import React, { useState } from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Menu from "../../components/menu/menu"
import Intro from "../../components/ativos/ativos-intro"
import Index from "../../components/ativos/ativos-index"
import List from "../../components/ativos/ativos-list"
import ListMobile from "../../components/ativos/ativos-listMobile"
import Footer from "../../components/footer/footer"

import font from "../../fonts/fonts.module.scss"

const PortfolioPage = ({data, location}) => {
  const [currentFilter, setCurrentFilter] = useState(location.state ? location.state.filter!==undefined ? location.state.filter : -1 : -1);
  const sections = [
    "APARTAMENTOS",
    "ESCRITÓRIOS",
    "ARMAZÉNS",
    "LOTES TERRENO"
  ];
  
  return(
    <Layout size={useBreakpoint().mobile ? null : ["clamp(100% - 72px, 100% - 2vw, 100% - 36px)", "clamp(36px, 2vw, 72px)"]} inv={data.globalJson.invisual}>
      <SEO title="Portefólio"/>
      {useBreakpoint().mobile ? (
        <>
          <Menu data={data.globalJson.menu}/>
          <Intro data={data.portfolioJson.introMobile} portMob/>
          <div style={{width: "100%", backgroundColor: "#000000", padding: "2vh"}}>
            <p className={font.aGB + " small"} style={{color: "#fff", textAlign: "center"}}>PROJETOS CONCLUÍDOS</p>
          </div>
          <ListMobile data={data.portfolioJson.projects} more={data.portfolioJson.more} current={currentFilter} sections={sections} port/>
          <Footer data={data.globalJson.footer} small/>
        </>
      ) : (
        <>
          <Menu data={data.globalJson.menu}/>
          <Index data={data.portfolioJson.sections} current={currentFilter} reset={setCurrentFilter} sections={sections}/>
          <Intro data={data.portfolioJson.intro} port/>
          <div style={{width: "100%", backgroundColor: "#000000", padding: "2vh"}}>
            <p className={font.aGB + " small"} style={{color: "#fff", textAlign: "center"}}>PROJETOS CONCLUÍDOS</p>
          </div>
          <List data={data.portfolioJson.projects} more={data.portfolioJson.more} current={currentFilter} port/>
          <Footer data={data.globalJson.footer}/>
        </>
      )}
    </Layout>
  )
}
export default PortfolioPage

export const Json = graphql`
  query portfolio {
    portfolioJson{
      intro{
        title
        text
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        moto
      }
      introMobile{
        title
        text
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        moto
      }
      sections{
        section
        subsections
      }
      selector
      selectorMobile
      more
      projects{
        name
        date
        local
        subsection
        typeInfo
        localInfo
        link
        linkTxt
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              width: 3840
            )
          }
        }
      }
    }
    globalJson{
      invisual{
        link
        linkTxt
      }
      menu{
        leftPage{
          background{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          content{
            subtitle
            title
            text
            link
            linkTxt
          }
          info{
            address
            contact{
              newsletter
              email
              phone
              txt
            }
          }
          livro{
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                width: 3840
              )
            }
          }
        }
        rightPage{
          bg{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          links{
            link
            linkTxt
          }
          highlights{
            subtitle
            title
            list{
              img{
                childImageSharp{
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    width: 3840
                  )
                }
              }
              title
              subtitle
              link
            }
          }
        }
      }
      footer{
        livro{
          childImageSharp{
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              width: 3840
            )
          }
        }
        bg{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contacts{
          email
          phone1
          txt
        }
        address
        privacyInfo{
          linkTxt
          link
        }
      }
    }
  }
`